import ChatWidget from './chat-widget';
import NavBar from './navbar';

export function PageLayout({ showChatWidget = true, children, ...props }: any) {
    return (
        <div className='flex flex-col min-h-screen'>
            <NavBar />
            {children}
            {showChatWidget && < ChatWidget />}
        </div>
    );
}
