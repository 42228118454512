import { useRoutes } from "react-router-dom";
import Login from "./pages/login";
import GuardRoute from "./routes/GuardRoute";
import PrivateRoute from "./routes/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChatProvider } from './stores/chat';

function App() {
  const routes = useRoutes([
    {
      path: "login",
      element: (
        <GuardRoute>
          <Login />
        </GuardRoute>
      ),
    },
    {
      path: "/*",
      element: (
        <GuardRoute isPrivate>
          <PrivateRoute />
        </GuardRoute>
      ),
    },
  ]);

  return (
    <ChatProvider>
      <div className="App">
        {routes}
        <ToastContainer autoClose={3000} hideProgressBar />
      </div >
    </ChatProvider>
  );
}

export default App;
