import { Amplify, ResourcesConfig } from "aws-amplify";

const AWS_CONFIG: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID as string,
      userPoolClientId: process.env
        .REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID as string,
    },
  },
};

Amplify.configure(AWS_CONFIG);
