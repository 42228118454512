'use client'

import { useState, useEffect, useRef, } from 'react'
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import ReactMarkdown from 'react-markdown'

import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "./ui/card"
import { ScrollArea } from "./ui/scroll-area"
import { Minimize2, Maximize2, X, Send, Loader2 } from 'lucide-react'

import { useChat } from '../stores/chat';

export default function ChatWidget() {
    const [inputMessage, setInputMessage] = useState("")
    const [isMinimized, setIsMinimized] = useState(false)
    const [isClosed, setIsClosed] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false)
    const scrollAreaRef = useRef<HTMLDivElement>(null)
    const { messages, sendMessage } = useChat();


    useEffect(() => {
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight
        }
    }, [messages])


    const handleSendMessage = async () => {
        if (inputMessage.trim() === "") return

        try {
            setIsProcessing(true)
            setInputMessage("")
            await sendMessage(inputMessage)

        } catch (error) {
            console.error('Failed to send message:', error)
        } finally {
            setIsProcessing(false)
        }
    }

    const toggleMinimize = () => setIsMinimized(prev => !prev)
    const toggleClose = () => setIsClosed(prev => !prev)

    if (isClosed) {
        return (
            <Button
                className="fixed bottom-4 right-4 z-50 shadow-lg transition-all duration-300 hover:shadow-xl"
                onClick={toggleClose}
            >
                Open Chat
            </Button>
        )
    }

    return (
        <Card
            className={`fixed bottom-4 right-4 w-full max-w-md transition-all duration-300 ease-in-out z-50 overflow-hidden shadow-2xl ${isMinimized ? "h-16" : "h-[550px]"
                }`}
        >
            <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4 bg-primary text-primary-foreground">
                <CardTitle className="text-lg font-semibold font-heading">
                    Lumineux Chat
                </CardTitle>
                <div className="flex space-x-2">
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={toggleMinimize}
                        className="text-primary-foreground hover:bg-primary-foreground/10"
                    >
                        {isMinimized ? (
                            <Maximize2 className="h-4 w-4" />
                        ) : (
                            <Minimize2 className="h-4 w-4" />
                        )}
                    </Button>
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={toggleClose}
                        className="text-primary-foreground hover:bg-primary-foreground/10"
                    >
                        <X className="h-4 w-4" />
                    </Button>
                </div>
            </CardHeader>
            {!isMinimized && (
                <CardContent className="p-0 flex flex-col h-[calc(100%-4rem)]">
                    <ScrollArea
                        className="flex-grow p-4 bg-dot-pattern"
                        ref={scrollAreaRef}
                    >
                        {messages.map((message) => (
                            <div
                                key={message.id}
                                className={`mb-4 flex ${message.role === "user" ? "justify-end" : "justify-start"
                                    }`}
                            >
                                <div
                                    className={`max-w-[80%] p-3 rounded-2xl ${message.role === "user"
                                        ? "bg-primary text-primary-foreground rounded-br-none"
                                        : "bg-secondary text-secondary-foreground rounded-bl-none"
                                        }`}
                                >
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            </div>
                        ))}
                        {isProcessing && (<Loader2 className="h-4 w-4 ml-2 inline animate-spin" />)}
                    </ScrollArea>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSendMessage()
                        }}
                        className="flex items-center p-4 bg-background border-t"
                    >
                        <Input
                            type="text"
                            placeholder="Type your message..."
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            className="flex-grow mr-2"
                            disabled={isProcessing}
                        />
                        <Button
                            type="submit"
                            size="icon"
                            className="bg-primary text-primary-foreground hover:bg-primary/90"
                            disabled={isProcessing}
                        >
                            <Send className="h-4 w-4" />
                        </Button>
                    </form>
                </CardContent>
            )}
        </Card>
    )
}