import axios from 'axios';
import { fetchAuthSession } from "aws-amplify/auth";

async function getAccessToken(): Promise<string> {
    const session = await fetchAuthSession();
    console.log("session", session)
    if (!session.tokens || !session.tokens?.idToken) {
        throw new Error("Invalid user session")
    }
    return session.tokens?.idToken?.toString();
}

const apiClient = axios.create({
    baseURL: 'https://api.ai-designlabs.com',
});

apiClient.interceptors.request.use(
    async (config) => {
        const token = await getAccessToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => Promise.reject(error)
);

export const getHttpClient = () => {
    return apiClient;
}
