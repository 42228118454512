import { useState, useCallback, useEffect } from 'react'
import { Button } from "../../components/ui/button"
import { Badge } from "../../components/ui/badge"
import { PageLayout } from "../../components/page-layout"
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '../../components/ui/card'

import { Document, getDocuments } from '../../apis/documents';

export default function Documents() {
    const [documents, setDocuments] = useState<Document[]>([])

    const fetchDocuments = useCallback(async () => {
        try {
            const documents = await getDocuments()
            setDocuments(documents)
        } catch (error) {
            console.error('Failed to fetch documents:', error)
            return []
        }
    }, [])

    useEffect(() => {
        fetchDocuments()
    }, [fetchDocuments])

    return (
        <PageLayout>
            <div className="w-full max-w-6xl mx-auto p-6">
                <Card>
                    <CardHeader>
                        <h1 className="text-2xl font-bold text-gray-800">Documents</h1>
                        <hr />
                    </CardHeader>
                    <CardContent>
                        <div className="relative w-full overflow-auto">
                            <table className="w-full caption-bottom">
                                <thead>
                                    <tr className="border-b transition-colors">
                                        <th className="h-12 px-4 text-left align-middle text-sm font-medium text-muted-foreground">
                                            Name
                                        </th>
                                        <th className="h-12 px-4 text-left align-middle text-sm font-medium text-muted-foreground">
                                            Description
                                        </th>
                                        <th className="h-12 px-4 text-left align-middle text-sm font-medium text-muted-foreground">
                                            Tags
                                        </th>
                                        <th className="h-12 px-4 text-left align-middle text-sm font-medium text-muted-foreground">
                                            Status
                                        </th>
                                        <th className="h-12 px-4 text-left align-middle text-sm font-medium text-muted-foreground">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents.map((document) =>
                                    (
                                        <tr
                                            key={document.documentId}
                                            className="border-b transition-colors hover:bg-muted/50"
                                        >
                                            <td className="p-4 align-middle text-sm">{document.name}</td>
                                            <td className="p-4 align-middle text-sm text-muted-foreground">
                                                {document.description}
                                            </td>
                                            <td className="p-4 align-middle">
                                                <div className="flex flex-wrap gap-1">
                                                    {document.tags.map((tag) => (
                                                        <Badge key={tag} variant="secondary" className="text-xs font-normal">
                                                            {tag}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            </td>
                                            <td className="p-4 align-middle text-sm">
                                                <span className={document.status === 'DONE' ? 'text-green-600' : 'text-yellow-600'}>
                                                    {document.status === "DONE" ? 'Processed' : 'Processing'}
                                                </span>
                                            </td>
                                            <td className="p-4 align-middle">
                                                <Link to={`/documents/${document.documentId}`}>
                                                    <Button
                                                        disabled={document.status !== 'DONE'}
                                                        className="shadow-lg transition-all duration-300 hover:shadow-xl"
                                                    >
                                                        View
                                                    </Button>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex justify-end pt-4 pb-4 pr-10 pl-4">
                            <Link to="/documents/new">
                                <Button className="right shadow-lg">
                                    Add
                                </Button>
                            </Link>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </PageLayout>
    )
}