import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/ui/loading";
import { getCurrentUser } from "aws-amplify/auth";

interface Props {
  isPrivate?: boolean;
  children: JSX.Element;
}

const getAuthenticatedUser = async () => {
  try {
    return await getCurrentUser();
  } catch (e) {
    return null
  }
}

function GuardRoute({ isPrivate = false, children }: Props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const user = await getAuthenticatedUser();
      if (!user && isPrivate) {
        navigate("/login", {
          replace: true,
        });
      }
      setLoading(false);
    })();
  }, []);

  if (loading) return <Loading />;

  return children;
}

export default GuardRoute;
