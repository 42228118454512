'use client'

import { useState, useEffect } from 'react'
import { ScrollArea } from "./ui/scroll-area"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"
import { Button } from "./ui/button"
import ReactMarkdown from 'react-markdown'
import { Loader2, ChevronLeft, ChevronRight } from 'lucide-react'

type DocumentPage = {
  page: number
  content: string
  recommendations: {
    compliance_checks: string[]
    feedbacks: string[]
  }
  source: string[]
  summary: string
}

export function DocumentViewer({ documentUrl = '' }) {
  const [documentData, setDocumentData] = useState<DocumentPage[]>([])
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(documentUrl)
        if (!response.ok) {
          throw new Error('Failed to fetch document data')
        }

        const { data } = await response.json()

        setDocumentData(data[0].result)
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred')
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [documentUrl])

  const goToNextPage = () => {
    if (currentPageIndex < documentData.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1)
    }
  }

  const goToPreviousPage = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1)
    }
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500">Error: {error}</p>
      </div>
    )
  }

  if (documentData.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>No document data available.</p>
      </div>
    )
  }

  const currentPage = documentData[currentPageIndex]

  console.log(currentPage)

  return (
    <div className="min-h-screen bg-background">
      <div className="container mx-auto py-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Page {currentPage.page} of {documentData.length}</h2>
          <div className="flex gap-2">
            <Button onClick={goToPreviousPage} disabled={currentPageIndex === 0}>
              <ChevronLeft className="h-4 w-4 mr-2" />
              Previous
            </Button>
            <Button onClick={goToNextPage} disabled={currentPageIndex === documentData.length - 1}>
              Next
              <ChevronRight className="h-4 w-4 ml-2" />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6">
          {/* Main Content */}
          <div className="col-span-8">
            <ScrollArea className="h-[calc(100vh-8rem)]">
              <div className="pr-6">
                <ReactMarkdown className="prose prose-sm max-w-none">
                  {currentPage.content}
                </ReactMarkdown>
              </div>
            </ScrollArea>
          </div>

          {/* Recommendations and Sources */}
          <div className="col-span-4">
            <ScrollArea className="h-[calc(100vh-8rem)]">
              <div className="space-y-6">
                <Tabs defaultValue="compliance_checks">
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="compliance_checks">Compliance Checks</TabsTrigger>
                    <TabsTrigger value="feedback">Feedback</TabsTrigger>
                  </TabsList>
                  <TabsContent value="compliance_checks">
                    <Card>
                      <CardHeader>
                        <CardTitle>Compliance Checks</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <ReactMarkdown className="prose prose-sm max-w-none">
                          {currentPage.recommendations.compliance_checks.map(check => `- ${check}`).join('\n')}
                        </ReactMarkdown>
                      </CardContent>
                    </Card>
                  </TabsContent>
                  <TabsContent value="feedback">
                    <Card>
                      <CardHeader>
                        <CardTitle>Feedback</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <ReactMarkdown className="prose prose-sm max-w-none">
                          {currentPage.recommendations.feedbacks.map(feedback => `- ${feedback}`).join('\n')}
                        </ReactMarkdown>
                      </CardContent>
                    </Card>
                  </TabsContent>
                </Tabs>

                <Card>
                  <CardHeader>
                    <CardTitle>Sources</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ul className="list-disc pl-5 space-y-2">
                      {currentPage.source.map((source, index) => (
                        <li key={index} className="text-sm">{source}</li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </div>
            </ScrollArea>
          </div>
        </div>
      </div>
    </div>
  )
}