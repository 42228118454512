import { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card"
import { Badge } from "../../components/ui/badge"
import { CheckCircle, Circle, Upload, Brain, FileText, ArrowRight } from 'lucide-react'

type Document = {
  id: number
  name: string
  description: string
  tags: string[]
  status: 'processing' | 'processed'
}

const documents: Document[] = [
  { id: 1, name: "Financial Report 2023", description: "Annual financial report for the year 2023", tags: ["finance", "annual"], status: "processed" },
  { id: 2, name: "Employee Handbook", description: "Company policies and procedures", tags: ["HR", "policies"], status: "processed" },
  { id: 3, name: "Product Roadmap", description: "Upcoming features and releases", tags: ["product", "planning"], status: "processing" },
  { id: 4, name: "Customer Survey Results", description: "Analysis of recent customer feedback", tags: ["customer", "feedback"], status: "processing" },
  { id: 5, name: "Marketing Strategy", description: "Q3 marketing initiatives and goals", tags: ["marketing", "strategy"], status: "processed" },
]

const steps = [
  { title: "Document Submission", description: "Upload document with details.", icon: Upload },
  { title: "Analysis & Processing", description: "Segment and analyze with LLM.", icon: Brain },
  { title: "Report Generation & Review", description: "Generate and view compliance report.", icon: FileText },
]

export function Documents() {
  const [activeTab, setActiveTab] = useState<string>("all")

  const filteredDocuments = documents.filter(doc =>
    activeTab === "all" || doc.status === activeTab
  )

  return (
    <div className="w-full max-w-6xl mx-auto mt-8">
      <Card className="p-6">
        <CardHeader>
          <h2 className="text-2xl font-bold tracking-tight">Recent documents</h2>
          <p className="text-muted-foreground">Track and manage your document review workflow</p>
        </CardHeader>

        <CardContent>
          <div className="flex justify-between items-center space-x-4 overflow-x-auto p-4">
            {steps.map((step, index) => (
              <div key={index} className="flex items-center space-x-4">
                <div className="flex flex-col items-center space-y-2 min-w-[120px]">
                  <div className="relative">
                    <div className="w-10 h-10 bg-primary text-primary-foreground rounded-full flex items-center justify-center">
                      <step.icon className="w-5 h-5" />
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="text-sm font-medium">{step.title}</p>
                    <p className="text-xs text-muted-foreground">{step.description}</p>
                  </div>
                </div>
                {index < steps.length - 1 && (
                  <ArrowRight className="w-5 h-5 text-muted-foreground flex-shrink-0" />
                )}
              </div>
            ))}
          </div>

          <Tabs defaultValue="all" className="w-full" onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="all">All</TabsTrigger>
              <TabsTrigger value="processing">Processing</TabsTrigger>
              <TabsTrigger value="processed">Processed</TabsTrigger>
            </TabsList>
            <TabsContent value="all" className="mt-6">
              <DocumentList documents={filteredDocuments} />
            </TabsContent>
            <TabsContent value="processing" className="mt-6">
              <DocumentList documents={filteredDocuments} />
            </TabsContent>
            <TabsContent value="processed" className="mt-6">
              <DocumentList documents={filteredDocuments} />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  )
}

function DocumentList({ documents }: { documents: Document[] }) {
  return (
    <div className="grid gap-4">
      {documents.map((doc) => (
        <Card key={doc.id}>
          <CardHeader>
            <div className="flex justify-between items-start">
              <div>
                <CardTitle className="text-lg">{doc.name}</CardTitle>
                <CardDescription>{doc.description}</CardDescription>
              </div>
              {doc.status === 'processed' ? (
                <CheckCircle className="text-green-500" />
              ) : (
                <Circle className="text-yellow-500" />
              )}
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex flex-wrap gap-2">
              {doc.tags.map((tag, index) => (
                <Badge key={index} variant="secondary">{tag}</Badge>
              ))}
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}