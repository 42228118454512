import { useRoutes } from "react-router-dom";
import Chat from "../pages/chat/index";
import Dashboard from "../pages/dashboard/index";
import Documents from "../pages/documents/index";
import NewDocument from "../pages/documents/create/index";
import Document from "../pages/documents/view/index";

import NotFound from "../pages/errors/404";


function PrivateRoute() {
  const routes = useRoutes([
    { path: "/", element: <Dashboard /> },
    { path: "/chat", element: <Chat /> },
    { path: "/documents", element: <Documents /> },
    { path: "/documents/new", element: <NewDocument /> },
    { path: "/documents/:id", element: <Document /> },
    { path: "*", element: <NotFound /> },
  ]);

  return routes;
}

export default PrivateRoute;
