import { ChatSession, addChatSession, ChatMessage, addChatMessage } from '../apis/chat';
import React, { createContext, useContext, useState } from 'react';

type ChatContextType = {
    messages: ChatMessage[];
    sendMessage: (message: string) => Promise<void>;
};

const ChatContext = createContext<ChatContextType | null>(null);

export function useChat() {
    const context = useContext(ChatContext);
    if (!context) {
        throw new Error("useChat must be used within a ChatProvider");
    }
    return context;
}

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [session, setSession] = useState<ChatSession | null>(null);

    const createSession = async () => {
        try {
            const response = await addChatSession();
            setSession(response);
            return response;
        } catch (error) {
            console.error("Failed to create session:", error);
            throw error;
        }
    };

    const sendMessage = async (message: string) => {
        let activeSession = session;

        if (!activeSession) {
            try {
                activeSession = await createSession();
                setSession(activeSession);
            } catch {
                console.error("Failed to initialize session. Message not sent.");
                return;
            }
        }

        const newMessage: ChatMessage = { id: null, sessionId: activeSession.sessionId, role: 'user', content: message };

        setMessages((prevMessages) => [...prevMessages, newMessage]);

        try {
            const response = await addChatMessage(newMessage);

            setMessages((prevMessages) => [...prevMessages, response]);
        } catch (error) {
            console.error("Failed to send message:", error);
        }
    };

    return (
        <ChatContext.Provider value={{ messages, sendMessage }}>
            {children}
        </ChatContext.Provider>
    );
};
