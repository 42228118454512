import { useState, useEffect, useCallback } from 'react'
import { Button } from "../../components/ui/button"
import { ScrollArea } from "../../components/ui/scroll-area"
import { Input } from "../../components/ui/input"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { PlusCircle, Send, MoreHorizontal, Trash, Edit2, Loader2 } from "lucide-react"
import { PageLayout } from "../../components/page-layout"
import ReactMarkdown from 'react-markdown'


import {
    ChatMessage,
    ChatSession,
    getChatSessions,
    getChatMessages,
    addChatSession,
    addChatMessage,
    deleteChatSession,
    updateChatSession
} from '../../apis/chat'

export default function Component() {
    const [chatSessions, setChatSessions] = useState<ChatSession[]>([])
    const [currentSessionId, setCurrentSessionId] = useState<string | null>(null)
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [inputMessage, setInputMessage] = useState('')
    const [editingSessionId, setEditingSessionId] = useState<string | null>(null)
    const [editingTitle, setEditingTitle] = useState('')
    const [isInitialized, setIsInitialized] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const fetchChatSessions = useCallback(async () => {
        try {
            const sessions = await getChatSessions()
            setChatSessions(sessions)
            return sessions
        } catch (error) {
            console.error('Failed to fetch chat sessions:', error)
            return []
        }
    }, [])

    const startNewChat = useCallback(async () => {
        try {
            const newSession = await addChatSession()
            setChatSessions(prev => [newSession, ...prev])
            setCurrentSessionId(newSession.sessionId)
            setMessages([])
            return newSession
        } catch (error) {
            console.error('Failed to create new chat session:', error)
            return null
        }
    }, [])

    useEffect(() => {
        const initializeChat = async () => {
            if (isInitialized) return

            const sessions = await fetchChatSessions()
            if (sessions.length === 0) {
                await startNewChat()
            } else {
                setCurrentSessionId(sessions[0].sessionId)
            }
            setIsInitialized(true)
        }
        initializeChat()
    }, [fetchChatSessions, startNewChat, isInitialized])

    useEffect(() => {
        if (currentSessionId) {
            fetchChatMessages(currentSessionId)
        }
    }, [currentSessionId])

    const fetchChatMessages = async (sessionId: string) => {
        try {
            const fetchedMessages = await getChatMessages(sessionId)
            setMessages(fetchedMessages)
        } catch (error) {
            console.error('Failed to fetch chat messages:', error)
        }
    }

    const selectChat = (sessionId: string) => {
        setCurrentSessionId(sessionId)
    }

    const deleteChat = async (sessionId: string) => {
        try {
            await deleteChatSession(sessionId)
            setChatSessions(prev => prev.filter(session => session.sessionId !== sessionId))
            if (currentSessionId === sessionId) {
                const remainingSessions = chatSessions.filter(session => session.sessionId !== sessionId)
                if (remainingSessions.length > 0) {
                    setCurrentSessionId(remainingSessions[0].sessionId)
                } else {
                    setCurrentSessionId(null)
                    setMessages([])
                }
            }
        } catch (error) {
            console.error('Failed to delete chat session:', error)
        }
    }

    const startEditingChat = (sessionId: string, currentTitle: string) => {
        setEditingSessionId(sessionId)
        setEditingTitle(currentTitle)
    }

    const saveEditedChat = async () => {
        if (editingSessionId) {
            try {
                const updatedSession = { sessionId: editingSessionId, title: editingTitle }
                await updateChatSession(updatedSession)
                setChatSessions(prev => prev.map(session =>
                    session.sessionId === editingSessionId ? updatedSession : session
                ))
                setEditingSessionId(null)
                setEditingTitle('')
            } catch (error) {
                console.error('Failed to update chat session:', error)
            }
        }
    }

    const sendMessage = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!inputMessage.trim() || !currentSessionId) return

        try {
            const message: ChatMessage = { id: null, sessionId: currentSessionId, role: 'user', content: inputMessage };
            setMessages(prev => [...prev, message])
            setInputMessage('')
            setIsProcessing(true)


            const response = await addChatMessage(message);
            setMessages(prev => [...prev, response])


        } catch (error) {
            console.error('Failed to send message:', error)
        } finally {
            setIsProcessing(false)
        }
    }

    return (
        <PageLayout showChatWidget={false}>
            <div className="grid h-[calc(100vh-65px)] grid-cols-[280px_1fr] bg-gray-50 dark:bg-gray-900">
                <div className="flex flex-col border-r border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-800">
                    <div className="p-4">
                        <Button onClick={startNewChat} className="w-full justify-start" variant="outline">
                            <PlusCircle className="mr-2 h-4 w-4" />
                            New Chat
                        </Button>
                    </div>
                    <div className="px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400">Recent Chats</div>
                    <ScrollArea className="flex-1">
                        {chatSessions.map(session => (
                            <div key={session.sessionId} className="group px-2 py-1">
                                <div className="flex items-center rounded-md border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 transition-colors duration-200">
                                    {editingSessionId === session.sessionId ? (
                                        <form onSubmit={(e) => { e.preventDefault(); saveEditedChat(); }} className="flex-1 px-2">
                                            <Input
                                                value={editingTitle}
                                                onChange={(e) => setEditingTitle(e.target.value)}
                                                onBlur={saveEditedChat}
                                                autoFocus
                                                className="h-8 text-sm"
                                            />
                                        </form>
                                    ) : (
                                        <Button
                                            onClick={() => selectChat(session.sessionId)}
                                            className={`w-full justify-start font-normal h-8 px-2 ${session.sessionId === currentSessionId ? 'bg-gray-100 dark:bg-gray-700' : ''}`}
                                            variant="ghost"
                                        >
                                            <span className="text-sm truncate">{session.title}</span>
                                        </Button>
                                    )}
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                            <Button
                                                size="icon"
                                                variant="ghost"
                                                className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                                            >
                                                <MoreHorizontal className="h-4 w-4" />
                                                <span className="sr-only">More options</span>
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent align="end">
                                            <DropdownMenuItem onClick={() => startEditingChat(session.sessionId, session.title)}>
                                                <Edit2 className="mr-2 h-4 w-4" />
                                                Rename
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => deleteChat(session.sessionId)}>
                                                <Trash className="mr-2 h-4 w-4" />
                                                Delete
                                            </DropdownMenuItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                            </div>
                        ))}
                    </ScrollArea>
                </div>

                <div className="flex flex-col bg-white dark:bg-gray-800">
                    <ScrollArea className="flex-1 p-4">
                        {messages.map((message) => (
                            <div key={message.id} className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
                                <div className={`inline-block p-3 rounded-lg ${message.role === 'user'
                                    ? "bg-primary text-primary-foreground rounded-br-none"
                                    : "bg-secondary text-secondary-foreground rounded-bl-none"
                                    }`}>
                                    <ReactMarkdown>{message.content}</ReactMarkdown>
                                </div>
                            </div>
                        ))}
                        {isProcessing && (<Loader2 className="h-4 w-4 ml-2 inline animate-spin" />)}
                    </ScrollArea>
                    <form onSubmit={sendMessage} className="p-4 border-t border-gray-200 dark:border-gray-700">
                        <div className="flex items-center space-x-2">
                            <Input
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                placeholder="Type your message..."
                                className="flex-1"
                                disabled={isProcessing}
                            />
                            <Button type="submit" size="icon" disabled={!currentSessionId || isProcessing}>
                                <Send className="h-4 w-4" />
                                <span className="sr-only">Send</span>
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </PageLayout>
    )
}