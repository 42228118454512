import { Button } from "../../components/ui/button";
import { AlertCircle, Link } from "lucide-react";

export default function NotFound() {
    return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 px-4 text-center dark:bg-gray-900">
            <div className="space-y-6">
                <AlertCircle className="mx-auto h-24 w-24 text-yellow-500 dark:text-yellow-400" />
                <h1 className="text-6xl font-bold text-gray-900 dark:text-gray-100">
                    404
                </h1>
                <h2 className="text-3xl font-semibold text-gray-700 dark:text-gray-300">
                    Page Not Found
                </h2>
                <p className="text-lg text-gray-600 dark:text-gray-400">
                    Oops! The page you're looking for doesn't exist or has been moved.
                </p>
                <Button asChild className="mt-4">
                    <Link href="/">Go back home</Link>
                </Button>
            </div>
        </div>
    );
}
