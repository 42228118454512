import { useState } from 'react'
import { Button } from '../../../components/ui/button'
import { Card, CardContent, CardHeader } from '../../../components/ui/card'
import { Input } from '../../../components/ui/input'
import { Loader2 } from 'lucide-react'
import { PageLayout } from "../../../components/page-layout"
import { toast } from "react-toastify";

import { Textarea } from "../../../components/ui/textarea"
import { Label } from "../../../components/ui/label"

import { addDocument, Document } from '../../../apis/documents';

export default function CreateDocument() {
    const [file, setFile] = useState<File | null>(null)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [tags, setTags] = useState('')

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0])
        }
    }

    const processDocument = async () => {
        if (!file) return;
        setLoading(true);

        try {
            const document: Document = {
                name,
                description,
                tags: tags.split(','),
                status: 'PENDING'
            };
            await addDocument(file, document)
            toast.success('Document submitted');
        } catch (error) {
            console.error('Error uploading file:', error)
            toast.error('Failed to upload document');
        } finally {
            setLoading(false);
        }
    }

    return (
        <PageLayout>
            <div className="container max-w-6xl mx-auto p-6">
                <Card>
                    <CardHeader>
                        <h1 className="text-2xl font-bold text-gray-800">Upload document</h1>
                        <hr />
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <form className="space-y-4">
                                <div>
                                    <Label htmlFor="name">Update document</Label>
                                    <Input
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        aria-describedby="name-error"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="description">Description</Label>
                                    <Textarea
                                        id="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                        aria-describedby="description-error"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="tags">Tags (comma-separated)</Label>
                                    <Input
                                        id="tags"
                                        value={tags}
                                        onChange={(e) => setTags(e.target.value)}
                                        aria-describedby="tags-error"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="file">Upload File</Label>
                                    <Input
                                        id="file"
                                        type="file"
                                        onChange={handleFileChange}
                                        required
                                        aria-describedby="file-error"
                                    />
                                </div>
                                <div className="flex justify-end pt-4 pb-4">
                                    <Button onClick={processDocument} disabled={!file || isLoading} className="right shadow-lg">
                                        {isLoading ? (
                                            <>
                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                Uploading...
                                            </>
                                        ) : (
                                            'Add'
                                        )}
                                    </Button>
                                </div>
                            </form>

                        </div>
                    </CardContent>
                </Card>
            </div>
        </PageLayout>
    )
}