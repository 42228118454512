import { getHttpClient } from './index';

export type ChatMessage = {
    id: string | null,
    sessionId: string,
    role: 'user' | 'assistant'
    content: string
}

export type ChatSession = {
    sessionId: string
    title: string
}


export const getChatSessions = async (): Promise<ChatSession[]> => {
    return (await getHttpClient().get('chat-sessions')).data.items
}

export const getChatMessages = async (sessionId: string): Promise<ChatMessage[]> => {
    const { data } = await getHttpClient().get(`chat-sessions/${sessionId}/chat-messages`);

    return data.items.reverse().flatMap((item: any) => [
        {
            id: `${item.messageId}-1`,
            sessionId: item.sessionId,
            role: "user",
            content: item.input
        },
        {
            id: `${item.messageId}-2`,
            sessionId: item.sessionId,
            role: "assistant",
            content: item.response
        }
    ]);
}

export const addChatSession = async (): Promise<ChatSession> => {
    const { data } = await getHttpClient().post('chat-sessions');
    return data;
}

export const addChatMessage = async (message: ChatMessage): Promise<ChatMessage> => {
    const { data } = await getHttpClient().post('chat-messages', {
        sessionId: message.sessionId, message: message.content
    })
    return {
        id: data.messageId,
        content: data.responseMessage,
        role: 'assistant',
        sessionId: message.sessionId
    }
}

export const deleteChatSession = async (sessionId: string): Promise<void> => {
    await getHttpClient().delete(`chat-sessions/${sessionId}`)
}

export const updateChatSession = async ({ sessionId, title }: ChatSession): Promise<void> => {
    await getHttpClient().patch(`chat-sessions/${sessionId}`, { title });
}
